// src/components/TypingSlogans.js
import React, { useState, useEffect } from 'react';
import './TypingSlogans.css'; // Import CSS for styling

const slogans = [
  { en: "Empowering Lives, One Donation at a Time.", te: "ప్రతి విరాళంతో జీవితాలను సశక్తం చేస్తోంది." },
  { en: "Compassion in Action: Making a Difference Together.", te: "చర్యలో దయ: కలిసి తేడా సృష్టించడం." },
  { en: "Your Support, Their Hope: Transforming Futures.", te: "మీ మద్దతు, వారి ఆశ: భవిష్యత్తులను మారుస్తోంది." },
  { en: "Join Us in Building a Better Tomorrow.", te: "మంచి రేపటి నిర్మాణంలో మాతో చేరండి." },
  { en: "Every Act of Kindness Counts: Donate Today.", te: "ప్రతి సౌమ్య చర్య ఎంతో విలువైనది: నేడు విరాళం ఇవ్వండి." },
  { en: "Together We Rise: Strengthening Communities.", te: "మనము కలిసి పెరుగుతున్నాము: సమాజాలను బలోపేతం చేయడం." },
  { en: "Make an Impact: Support Our Foundation.", te: "ప్రభావం చూపండి: మా ఫౌండేషన్‌ను మద్దతు ఇవ్వండి." }
];

const TypingSlogans = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [language, setLanguage] = useState('en'); // Track language

  useEffect(() => {
    const currentSlogan = slogans[currentIndex];
    const sloganText = language === 'en' ? currentSlogan.en : currentSlogan.te;

    const interval = setInterval(() => {
      setText(prev => {
        if (!isDeleting) {
          return sloganText.substring(0, prev.length + 1);
        } else {
          return sloganText.substring(0, prev.length - 1);
        }
      });

      if (!isDeleting && text === sloganText) {
        setTimeout(() => setIsDeleting(true), 1000); // Pause before deleting
      } else if (isDeleting && text === "") {
        setIsDeleting(false);
        setLanguage(prev => (prev === 'en' ? 'te' : 'en')); // Switch language
        setCurrentIndex((prev) => (prev + 1) % slogans.length);
      }
    }, isDeleting ? 50 : 100); // Adjust speed (150 ms for deleting, 300 ms for typing)

    return () => clearInterval(interval);
  }, [text, isDeleting, currentIndex, language]);

  return (
    <div className="typing-slogans">
      <div className="typing-text">
        {text}
        <span className="cursor">|</span>
      </div>
    </div>
  );
};

export default TypingSlogans;
