import React, { useState } from 'react';
import './MediaRoom.css';
import NewsScroller from '../Home/Carousel';
import n1 from './newspapers/n1.png';
import n2 from './newspapers/n2.png';
import n3 from './newspapers/n3.png';
import n4 from './newspapers/n4.png';
import n5 from './newspapers/n5.png';
import n6 from './newspapers/n6.png';
import n7 from './newspapers/n7.png';
import n8 from './newspapers/n8.png';
import n9 from './newspapers/n9.png';
import n10 from './newspapers/n10.png';
import n11 from './newspapers/n11.png';
import n12 from './newspapers/n12.png';
import n13 from './newspapers/n13.png';
import n14 from './newspapers/n14.png';
import n15 from './newspapers/n15.png';
import n16 from './newspapers/n16.png';
import n17 from './newspapers/n17.png';
import n18 from './newspapers/n18.png';
import n19 from './newspapers/n19.png';
import n20 from './newspapers/n20.png';
import n21 from './newspapers/n21.png';
import n22 from './newspapers/n22.png';
import n23 from './newspapers/n23.png';
import n24 from './newspapers/n24.png';
import n25 from './newspapers/n25.png';
import n26 from './newspapers/n26.png';
import n27 from './newspapers/n27.png';
import n28 from './newspapers/n28.png';
import n29 from './newspapers/n29.png';
import n30 from './newspapers/n30.png';
import n31 from './newspapers/n31.png';
import n32 from './newspapers/n32.png';

const mediaData = {
    newspapers: [
        { id: 1, imageUrl: n1 },
        { id: 2, imageUrl: n2 },
        { id: 3, imageUrl: n3 },
        { id: 4, imageUrl: n4 },
        { id: 5, imageUrl: n5 },
        { id: 6, imageUrl: n6 },
        { id: 7, imageUrl: n7 },
        { id: 8, imageUrl: n8 },
        { id: 9, imageUrl: n9 },
        { id: 10, imageUrl: n10 },
        { id: 11, imageUrl: n11 },
        { id: 12, imageUrl: n12 },
        { id: 13, imageUrl: n13 },
        { id: 14, imageUrl: n14 },
        { id: 15, imageUrl: n15 },
        { id: 16, imageUrl: n16 },
        { id: 17, imageUrl: n17 },
        { id: 18, imageUrl: n18 },
        { id: 19, imageUrl: n19 },
        { id: 20, imageUrl: n20 },
        { id: 21, imageUrl: n21 },
        { id: 22, imageUrl: n22 },
        { id: 23, imageUrl: n23 },
        { id: 24, imageUrl: n24 },
        { id: 25, imageUrl: n25 },
        { id: 26, imageUrl: n26 },
        { id: 27, imageUrl: n27 },
        { id: 28, imageUrl: n28 },
        { id: 29, imageUrl: n29 },
        { id: 30, imageUrl: n30 },
        { id: 31, imageUrl: n31 },
        { id: 32, imageUrl: n32 }
    ]
};

const MediaRoom = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const handleClose = () => {
        setSelectedImage(null);
    };

    return (
        <div className="media-room-container">
            <h1>Media Room</h1>

            <section className="media-section">
                <h2>These are The Some Famous News Article's About Our Excellence</h2>
                <div className="media-grid">
                    {mediaData.newspapers.map((newspaper) => (
                        <div 
                            key={newspaper.id} 
                            className="media-box"
                            onClick={() => handleImageClick(newspaper.imageUrl)}
                            aria-label={`View Newspaper ${newspaper.id}`}
                            role="button"
                            tabIndex={0}
                        >
                            <img 
                                src={newspaper.imageUrl} 
                                alt={`Newspaper ${newspaper.id}`} 
                                className="media-thumbnail"
                            />
                        </div>
                    ))}
                </div>
            </section>

            {selectedImage && (
                <div className="modal-overlay active" onClick={handleClose}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <img src={selectedImage} alt="Selected Newspaper" className="modal-image" />
                        <button 
                            className="modal-close" 
                            onClick={handleClose} 
                            aria-label="Close modal"
                        >
                            &times;
                        </button>
                    </div>
                </div>
            )}
              <div className="news-section">
         <h2>Latest News</h2>
         <NewsScroller />
       </div>
        </div>
    );
};

export default MediaRoom;
