import React from 'react';
import annapurnaImage from '../images/temp/annapurna.jpg'; // Update with actual paths
import thulasiImage from '../images/temp/tulasi.jpg';
import kavithaImage from '../images/temp/kavitha.jpg';
import narasimhaImage from '../images/temp/narasimha.jpg';
import jaylaxmiImage from '../images/temp/orphan.jpg';
import Image1 from '../images/temp/venkatesham.jpg';
import Image2 from '../images/temp/chandrasekharreddy.jpg';
import './Succes_story.css';
import NewsScroller from '../Home/Carousel';

const SuccessStories = () => {
    return (
        <>
        <div className="success-stories">
            <h2>Our Success Stories</h2>
            <div className="story">
                <div className="story-text">
                    <h3>MS. ANNAPURNA</h3>
                    <p>
                        She was met with a serious road accident while she was going to her house after the job, 
                        with multiple fractures, maxilla facial trauma, breast collapse, spinal injury, hip joint 
                        fracture, thigh muscle collapse, gluteus muscle collapse, knee & femur collapse. She was 
                        performed multiple operations in various hospitals after sixteen (16) surgeries and six (6) 
                        months of rehabilitation, she is a normal being. The whole treatment is provided for free of 
                        service. Now, she has accepted employment in the Government Hospital as “Nurse”.
                    </p>
                </div>
                <img src={annapurnaImage} alt="Ms. Annapurna" className="story-image" />
               
            </div>
            <div className="story">
                <div className="story-text">
                    <h3>MS. THULASI</h3>
                    <p>
                        She was met with a serious road accident hit by RTC bus in Balanagar, Secundrabad. She was 
                        severely injured with multiple fractures for her knee and hip. She was operated two (2) times 
                        and the foundation treated her for three (3) months for free. Now, she is quite normal and 
                        leading a happy marital life with children.
                    </p>
                </div>
                <img src={thulasiImage} alt="Ms. Thulasi" className="story-image" />
            </div>
            <div className="story">
                <div className="story-text">
                    <h3>MS. KAVITHA</h3>
                    <p>
                        She is an Orphan and possess master’s in science with Mathematics. She married an uneducated 
                        individual, who was an alcoholic, burnt her alive. She suffered with severe burns and was 
                        hospitalized for more than one month for treatment. The Foundation treated her for six (6) 
                        months and free and placed her with employment.
                    </p>
                </div>
                <img src={kavithaImage} alt="Ms. Kavitha" className="story-image" />
            </div>
            <div className="story">
                <div className="story-text">
                    <h3>MR. NARASIMHA</h3>
                    <p>
                        He was a Car Driver, suddenly he got brain stroke, and had a Craniotomy Surgery, and the 
                        foundation is providing post operation care for more than one (1) year for free. He is presently 
                        getting treated in the center. We have many more success stories to share.
                    </p>
                </div>
                <img src={narasimhaImage} alt="Mr. Narasimha" className="story-image" />
            </div>
            <div className="story">
                <div className="story-text">
                    <h3>ADOPTED ORPHAN</h3>
                    <p>
                        The Foundation also adopted an orphan Ms. Jaylaxmi from Warangal and provided her employment 
                        and got her married. She is happily married with a child.
                    </p>
                </div>
                <img src={jaylaxmiImage} alt="Ms. Jaylaxmi" className="story-image" />
            </div>
            {/* <img src={Image1} alt="Ms. Jaylaxmi" className="story-image1" />
            <img src={Image2} alt="Ms. Jaylaxmi" className="story-image1" /> */}
            <div className="news-section">
        <h2>Latest News</h2>
        <NewsScroller />
      </div>
        </div>
        
      </>
    );
};

export default SuccessStories;