// src/components/Navbar.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from "./logo.png"
import './Navbar.css'; // Import CSS for styling


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => location.pathname === path ? 'active' : '';

  return (
    <nav className={`navbar ${isOpen ? 'open' : ''}`}>
      <div className="navbar-header">
        <img src={logo} alt="RK's Foundation Logo" className="logo" />
        <h1>RK's Foundation</h1>
        <h3>Service With a Smile</h3>
        <button className="toggle-button" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </button>
      </div>
      <ul className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
        <li className={isActive('/')}><Link to="/">Home</Link></li>
        <li className={isActive('/about-us')}>
          <Link to="/about_founder">About Us</Link>
          <ul className="dropdown">
            <li><Link to="/about_founder">About the Founder</Link></li>
            <li><Link to="/success_stories">Our Success Stories</Link></li>
            <li><Link to="/vip_opinion">VIP's Opinions</Link></li>
            <li><Link to="/advisors_community">Our Advisors Community</Link></li>
          </ul>
        </li>
        <li className={isActive('/services')}><Link to="/Services">Services</Link></li>
        <li className={isActive('/mediaroom')}><Link to="/media_room">Media Room</Link></li>
        <li className={isActive('/gallery')}>
          <Link to="/gallery">Gallery</Link>
          <ul className="dropdown">
            <li><Link to="/gallery">Photos</Link></li>
            <li><Link to="/videos">Videos</Link></li>
          </ul>
        </li>
        <li className={isActive('/contact-us')}><Link to="/contact_us">Contact Us</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
