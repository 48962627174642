import React from 'react';
import './Services.css';
import NewsScroller from '../Home/Carousel';

const servicesData = [
    {
        title: 'NEURO',
        description: 'Providing comprehensive care for neurological disorders, including diagnosis, treatment, and rehabilitation.',
        imageUrl: 'https://m.economictimes.com/thumb/msid-84404573,width-1200,height-900,resizemode-4,imgsize-283605/neuro-disease.jpg'
    },
    {
        title: 'PARALYSIS',
        description: 'Specialized treatment and rehabilitation programs for patients suffering from paralysis to improve their quality of life.',
        imageUrl: 'https://cdn.flintrehab.com/uploads/2022/07/spastic-or-flaccid-paralysis.jpg'
    },
    {
        title: 'BRAIN TUMOR',
        description: 'Advanced diagnostic and treatment options for brain tumors, including surgery, radiation, and chemotherapy.',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTh2RbBeVmPA_2P0w9y1IEVu59sRTkUyX0YYg&s'
    },
    {
        title: 'CARDIO VASCULAR DISEASES',
        description: 'Comprehensive cardiovascular care including prevention, diagnosis, and treatment of heart diseases.',
        imageUrl: 'https://www.endocrine.org/-/media/endocrine/images/patient-engagement-webpage/condition-page-images/cardiovascular-disease/cardio_disease_t2d_pe_1796x943.jpg'
    },
    {
        title: 'CANCER',
        description: 'Cutting-edge cancer treatment and support services, including chemotherapy, radiation therapy, and palliative care.',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZqW4U2gAUUeM0Wxf03QEK4TzW-8n1lFrnvQ&s'
    },
    {
        title: 'TUBERCULOSIS',
        description: 'Effective diagnosis and treatment of tuberculosis, focusing on both pulmonary and extrapulmonary TB.',
        imageUrl: 'https://www.advocatehealth.com/assets/images/health-services/lung-with-tuberculosis.webp'
    },
    {
        title: "ALZHEIMER'S DISEASE",
        description: 'Supportive care and treatment for patients with Alzheimer’s, aiming to improve their quality of life.',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIVQ1ZekZgjE6mLRq44aRXzVqHlV_yX7Zn4g&s'
    },
    {
        title: 'STROKE',
        description: 'Comprehensive stroke care, from emergency treatment to rehabilitation and recovery support.',
        imageUrl: 'https://www.healthywomen.org/media-library/stroke-vs-heart-attack-know-the-signs-symptoms.png?id=32931472&width=1200&height=600&coordinates=0%2C63%2C0%2C64'
    },
    {
        title: 'LEPROSY',
        description: 'Expert treatment and care for patients with leprosy, focusing on medical and social rehabilitation.',
        imageUrl: 'https://resize.indiatvnews.com/en/resize/newbucket/1200_-/2024/02/leprosy-free-india-1707129778.jpg'
    },
    {
        title: 'BED SORES',
        description: 'Preventive and therapeutic care for bed sores, ensuring comfort and faster recovery for bedridden patients.',
        imageUrl: 'https://www.rosenfeldinjurylawyers.com/wp-content/uploads/2023/10/sdti-1.jpg'
    },
    {
        title: 'ORTHO',
        description: 'Advanced orthopedic care, including surgery and rehabilitation for musculoskeletal conditions.',
        imageUrl: 'https://productimages.withfloats.com/serviceimages/tile/6383521bf263e5154f104345header-mob'
    },
    {
        title: 'PARKINSONS',
        description: 'Specialized treatment and support for patients with Parkinson’s disease to manage symptoms and improve mobility.',
        imageUrl: 'https://physioheal.com/wp-content/uploads/2023/08/parkinson-disease.jpg'
    },
    {
        title: 'ASTHMA',
        description: 'Comprehensive asthma management, including diagnosis, treatment, and long-term care plans.',
        imageUrl: 'https://images.onlymyhealth.com/imported/images/2024/May/07_May_2024/Main-asthma.jpg'
    },
    {
        title: 'CHRONIC KIDNEY DISEASE',
        description: 'Expert care for patients with chronic kidney disease, including dialysis and transplant support.',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtQu8K1rW7aXpnD051H1Q4JSxZWBSb1sj-Gw&s'
    },
    {
        title: 'HEPATITIS',
        description: 'Diagnosis, treatment, and management of various types of hepatitis to prevent liver damage.',
        imageUrl: 'https://livercaremumbai.com/wp-content/uploads/2022/01/hepatitis-s1-liver-hepatitis-virus.webp'
    },
    {
        title: 'ARTHRITIS',
        description: 'Advanced care for arthritis patients, including medication, physical therapy, and lifestyle management.',
        imageUrl: 'https://www.mayoclinic.org/-/media/kcms/gbs/patient-consumer/images/2013/08/26/10/47/ds00020_im02689_r7_rheumatoidarthritisthu_jpg.jpg'
    },
    {
        title: 'DIABETES',
        description: 'Comprehensive diabetes care, focusing on prevention, management, and complication prevention.',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLi70qOKZ3jxewc1dsTeaQ6kwJkv8xQkSuBQ&s'
    },
    {
        title: '... ETC ...',
        description: 'And many more services to ensure the health and well-being of our community.',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbYSotCSBU6K3Ws59B6C-OqxOYghmMPVTFyUj4D3GazPIH-q4DhVDVRUNg7XYNqCjR_v0&usqp=CAU'
    }
];

const Services = () => {
    return (
        <>
        <div className="services-container">
            <h1>Our Services</h1>
            <div className="services-list">
                {servicesData.map((service, index) => (
                    <div key={index} className="service-item">
                        <img src={service.imageUrl} alt={service.title} className="service-image" />
                        <div className="service-content">
                            <h2>{service.title}</h2>
                            <p>{service.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="news-section">
         <h2>Latest News</h2>
         <NewsScroller />
       </div>
        </div>
        
       </>
    );
}

export default Services;
