import React from 'react';
import './loade.css'; // Make sure to import the CSS file

const LoadingSpinner = () => (
  <div className="row cf">
    <div className="three col">
      <div className="loader" id="loader-5">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
);

export default LoadingSpinner;
