// ContactUs.js
import React, { useState } from 'react';
import './ContactUs.css';
import NewsScroller from '../Home/Carousel';

const ContactUs = () => {
  const [result, setResult] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "1ada7fca-71e3-40a5-bb7c-009bb364d05e");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();
      } else {
        setResult(`Error: ${data.message}`);
      }
    } catch (error) {
      setResult("Error: Something went wrong.");
    }
  };

  return (
    <div className="contact-us-page">
      <div className="contact-us-intro">
        <h1>Contact Us</h1>
        <p>
          RK’s Foundation is a not-for-profit (NGO) foundation, operating a medical center since 2008. 
          It is registered under the Societies Registration Act, 2001 with its Registered Number.434, and possesses 
          80 G Certification under the Income Tax Act, providing benefits to donors. We also have 12 A Registration, 
          making us an NGO exempt from tax.
        </p>
      </div>

      <div className="contact-us-content">
        <div className="contact-us-form">
          <h2>Get in Touch</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" placeholder="Enter your name" required />

            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" placeholder="Enter your email" required />

            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="5" placeholder="Enter your message" required></textarea>

            <button type="submit">Submit</button>
          </form>
          <span className="form-result">{result}</span>
        </div>

        <div className="contact-us-map">
          <h2>Find Us on the Map</h2>
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.0333251510215!2d78.49491317383087!3d17.458119000766235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9a69aaaaaa97%3A0xc868d0c0bf1f4ff4!2sRK%20FOUNDATION%20%26%20ELDER%20CARE%20CENTER!5e0!3m2!1sen!2sin!4v1722401288384!5m2!1sen!2sin" 
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            style={{ border: 0 }}
          ></iframe>
        </div>
      </div>

      <div className="news-section">
        <h2>Latest News</h2>
        <NewsScroller />
      </div>
    </div>
  );
};

export default ContactUs;
