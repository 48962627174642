// Videos.js
import React from 'react';
import './Videos.css'; // Import your styles
import NewsScroller from '../Home/Carousel';
import v1 from '../videos/1.mp4';
import v2 from '../videos/2.mp4';
import v3 from '../videos/3.mp4';
import v4 from '../videos/4.mp4';
import v5 from '../videos/5.mp4';
import v6 from '../videos/6.mp4';
import v7 from '../videos/7.mp4';
import v8 from '../videos/8.mp4';
import v9 from '../videos/9.mp4';
import v10 from '../videos/10.mp4';
import v11 from '../videos/maanavi.mp4';
import v12 from '../videos/memu.webm';

const Videos = () => {
  const videoList = [
    // { title: 'Video 1', src: v1, description: 'An introduction to our project' },
    { title: 'Video 2', src: v2, description: 'Highlights from the event' },
    { title: 'Video 3', src: v3, description: 'A day in the life' },
   
    // { title: 'Video 5', src: v5, description: 'Our community impact' },
    { title: 'Video 6', src: v6, description: 'Upcoming projects' },
    { title: 'Video 7', src: v7, description: 'Volunteer testimonials' },
    // { title: 'Video 8', src: v8, description: 'Behind the scenes' },
    { title: 'Video 9', src: v9, description: 'Fundraising event' },
    { title: 'Video 10', src: v10, description: 'Media coverage' },
    { title: 'Video 11', src: v11, description: 'Special announcements' },
    { title: 'Video 12', src: v12, description: 'Thank you message' }
  ];

  return (
    <div className="videos-page">
      <h1 className="videos-title">Videos</h1>
      <div className="videos-grid">
        {videoList.map((video, index) => (
          <div className="video-item" key={index}>
            {/* <h2 className="video-title">{video.title}</h2> */}
            <video className="video-player" controls>
              <source src={video.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
           
          </div>
        ))}
      </div>
      <div className="news-section">
    <h2>Latest News</h2>
    <NewsScroller />
  </div>
    </div>
  );
};

export default Videos;
