import React from 'react';
import Slider from 'react-slick';
import NewsScroller from './Carousel';
import TypingSlogans from './TypingSlogans';
import logo from '../logo.png';
import img1 from './awrd.jpg';
import img2 from './IMG-20240729-WA0019.jpg';
import img3 from './3.jpg';
import img4 from './4.jpg';
import img5 from './5.jpg';
import img6 from './main1.jpg';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Home.css';

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set the speed for autoplay (3 seconds)
   
    
  };
  

  return (
    <div className="home">
      
      <Slider {...settings} className="home-carousel">
      <div>
          <img
            className="d-block w-100"
            src={img6}
            alt="First slide"
          />
         
        </div>
        <div>
          <img
            className="d-block w-100"
            src={img2}
            alt="First slide"
          />
         
        </div>

        <div>
          <img
            className="d-block w-100"
            src={img3}
            alt="First slide"
          />
         
        </div>
        <div>
          <img
            className="d-block w-100"
            src={img4}
            alt="Second slide"
          />
          
        </div>
        <div>
          <img
            className="d-block w-100"
            src={img5}
            alt="Third slide"
          />
          
        </div>
        <div>
          <img
            className="d-block w-100"
            src={img1}
            alt="Third slide"
          />
         
        </div>
      </Slider>
      <div className="welcome-section">
        
        <h1>Welcome to RK's Foundation</h1>
        <p>
          RK’s Foundation is a not-for-profit (NGO) foundation, operating a medical center since 2008. It is registered under the Societies Registration Act, 2001 with its Registered Number 434, and possesses 80G Certification under the Income Tax Act, which provides benefits to the donors and we also possess 12A Registration as an NGO exempt from Tax.
        </p>
        <p>
          We operate an Elder Care Center, Rehabilitation Center, Physiotherapy, Nursing Care, Caretaker Services, Counseling, Medical Services for aged patients, indigent patients, destitute patients, orphans, neurological, ortho, cancer, health care awareness programs, geriatric care, and treatment for terminal illness patients.
        </p>
        <p>
          Our Health Care Center treats patients suffering from chronic diseases such as Neuro, Paralysis, Brain Tumor, Cardiovascular Diseases, Cancer, Tuberculosis, Alzheimer’s Disease, Heart and Brain Stroke, Parkinson’s, Asthma, Chronic Kidney, Hepatitis, Arthritis, Diabetes, and Orthopedics. We provide medications to our needy patients.
        </p>
        <p>
          Our facility employs more than 25 medical and paramedical staff. Our center has 50 beds, and we provide free meals to our in-house patients and to our staff daily for more than 60 individuals.
        </p>
        <p>
          Vision: Our goal is to acquire our own premises and improve our facility to treat disabled patients, operate an orphanage, elder care, and provide free treatment for poor people.
        </p>
        
        <div className="slogans-section">
          <h2>Our Mission</h2>
          <TypingSlogans />
        </div>
        <div className="doctors-box">
          
        <div className="doctors-content">
        <h2>Our Medical Team</h2>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Qualifications</th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>Dr.K.Ram Singh</td>
                <td>MS (Gen. Surgeon),Laproscopic Surgeon Professor</td>
              </tr>
              <tr>
                <td>Dr.K.S.Pramod Kumar</td>
                <td>M.D General Physician</td>
              </tr>
              <tr>
                <td>Dr. Hari Kumar</td>
                <td>MBBS, MS (Gen. Surgery), M.Sc(Psy)</td>
              </tr>
              <tr>
                <td>Dr.B.D.Chhetri</td>
                <td>M.D General Physician</td>
              </tr>
              
              <tr>
                <td>Dr.Y.Anand Rao</td>
                <td>BPT Physiotherapist</td>
              </tr>
              <tr>
                <td>Dr.B.Rama Krishna</td>
                <td>BPT Physiotherapist</td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <img
          className="doctors-logo"
          src={logo}
          alt="Foundation Logo"
        />
        <img
          className="doctors-logo1"
          src="https://rksmotherteresafoundation.com/images/temp/ramakrishna.jpg"
          alt="Foundation Logo"
        />
         
      </div>
     
      </div>
      <div className="news-section">
        <h2>Latest News</h2>
        <NewsScroller />
      </div>
    </div>
  );
};

export default Home;
