import React from 'react';
import founderImage1 from '../images/temp/about_founder.jpg';  // Update the path accordingly
 // Update the path accordingly
import founderImage3 from '../images/temp/boora.jpg'; 
import './about-founder.css';
import NewsScroller from '../Home/Carousel';
const AboutFounder = () => {
    return (
        <div className="about-founder">
            <h2>About The Founder</h2>
            <div className="founder-content">
                <div className="founder-text">
                    <p>
                        The Founder of the RK’s Foundation is Dr. Rama Krishna who has completed 
                        Bachelor of Physiotherapy from NTR Health University in the year 2011. 
                        The idea of starting this foundation came to his mind when he lost his mother 
                        late Ramulamma, who could not get proper medical assistance in his hometown. 
                        She passed away without getting proper medical attention and treatment in a 
                        small village. This sad incident inspired him to take an oath that he shall serve 
                        and treat thousands of mothers like his own by providing the best medical care 
                        for the needy in rural and urban India.
                    </p>
                    <p>
                        Since the inception of this foundation, he has treated more than 15000 (Fifteen Thousand) 
                        patients suffering from chronic health diseases. He has treated many victims of road 
                        accidents, orphans, destitute women, children, elderly men and women, accident patients, 
                        and fire victims. He is continuously providing rehabilitation services.
                    </p>
                </div>
                <div className="founder-image">
                    <img src={founderImage1} alt="Dr. Rama Krishna" />
                </div>
            </div>
            <h3>Awards and Achievements</h3>
            <ul>
                <li>“Best Doctor for Outstanding Contribution in rehabilitation & Physiotherapy” by Nationwide Health Care Conclave & Awards, 2020</li>
                <li>“VaidhyaRatna” awardee 2019, by the Government of Telangana</li>
                <li>“Indian Humanitarian Award”, 2020 by Indian Book of Records</li>
                <li>Awarded as the “Best Doctor” and “Good Service Award” by the World Human Rights Council and by the Social Welfare Department of Telangana</li>
                <li>Appreciations by Rotary Club, Lions Club, City Police Commissioner, Hyderabad, and City Police Commissioner of Cyberabad</li>
            </ul>
            <div className="founder-content">
                <div className="founder-text">
                    <p>
                        Further to his services, his spouse is also engaged as “Nursing Director” for the facility for more 
                        than ten (10) years. She is also completing her Bachelor of Physiotherapy to assist Dr. Rama Krishna 
                        in his endeavors.
                    </p>
                </div>
                <div className="founder-image">
                    
                    {/* <img src={founderImage3} alt="Dr. Rama Krishna in action" /> */}
                </div>

                
            </div>

            <div className="news-section">
        <h2>Latest News</h2>
        <NewsScroller />
      </div>
        </div>
    );
};

export default AboutFounder;
