// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Import CSS for styling
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer-container">
      <footer className="footer">
        <div className="footer-section">
          <h2>Address</h2>
          <p>RK's Foundation</p>
          <p>Plot No. 38, ABM PLAZA,</p>
          <p>Janakpuri Colony,</p>
          <p>Beside SBI Bank, Karkhana,</p>
          <p>Secunderabad - 500009</p>
          <p>Telangana State, India</p>
          <p>Phone: +91 9959932323</p>
          <p>040 - 27832323</p>
        </div>
        <div className="footer-section">
          <h2>Stories</h2>
          <ul>
            <li><Link to="/success_stories">Annapoorna Story</Link></li>
            <li><Link to="/success_stories">Bholaxmi Story</Link></li>
            <li><Link to="/success_stories">Thulasi Story</Link></li>
            <li><Link to="/success_stories">Kavitha Story</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h2>Founder & Presidents</h2>
          <p>Founder & President - Dr. Rama Krishna Bongu</p>
          <p>General Secretary - Shri Nagabhushana Bongu</p>
        </div>
        <div className="footer-section">
          <h2>Quick Links</h2>
          <ul>
            <li><Link to="/privacy_policy">Privacy Policy</Link></li>
            <li><Link to="/terms_conditions">Terms And Conditions</Link></li>
          </ul>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>Design and Developed by <a href="https://vedantsolutions.com" target="_blank" rel="noopener noreferrer">Vedant Solutions</a></p>
      </div>
    </div>
  );
};

export default Footer;
