import React, { useState } from 'react';
import Modal from 'react-modal';
import './photos.css'; // Add your own CSS styling
import NewsScroller from '../Home/Carousel';
import s1 from '../photos/swathilakra/1.jpg';
import s2 from '../photos/swathilakra/2.jpg';
import s3 from '../photos/swathilakra/3.jpg';
import s4 from '../photos/swathilakra/4.jpg';
import s5 from '../photos/swathilakra/5.jpg';
import s6 from '../photos/swathilakra/6.jpg';
import s7 from '../photos/swathilakra/7.jpg';
import s8 from '../photos/swathilakra/8.jpg';
import k1 from '../photos/krishnasagar/1.jpg';
import k2 from '../photos/krishnasagar/2.jpg';
import k3 from '../photos/krishnasagar/3.jpg';
import k4 from '../photos/krishnasagar/4.jpg';
import k5 from '../photos/krishnasagar/5.jpg';
import k6 from '../photos/krishnasagar/6.jpg';
import k7 from '../photos/krishnasagar/7.jpg';
import su1 from '../photos/sudhakarnaik/1.jpg';
import su2 from '../photos/sudhakarnaik/2.jpg';
import su3 from '../photos/sudhakarnaik/3.jpg';
import su4 from '../photos/sudhakarnaik/4.jpg';
import su5 from '../photos/sudhakarnaik/5.jpg';
import su6 from '../photos/sudhakarnaik/6.jpg';
import su7 from '../photos/sudhakarnaik/7.jpg';
import r1 from '../photos/ramakotaiah/1.jpg';
import r2 from '../photos/ramakotaiah/2.jpg';
import r3 from '../photos/ramakotaiah/3.jpg';
import r4 from '../photos/ramakotaiah/4.jpg';
import t1 from '../photos/tpdas/1.jpg';
import t2 from '../photos/tpdas/2.jpg';
import t3 from '../photos/tpdas/3.jpg';
import t4 from '../photos/tpdas/4.jpg';
import aj1 from '../photos/ajithreddy/1.jpg';
import aj2 from '../photos/ajithreddy/2.jpg';
import aj3 from '../photos/ajithreddy/3.jpg';
import aj4 from '../photos/ajithreddy/4.jpg';
import aj5 from '../photos/ajithreddy/5.jpg';
import aj6 from '../photos/ajithreddy/6.jpg';
import aj7 from '../photos/ajithreddy/7.jpg';
import aj8 from '../photos/ajithreddy/8.jpg';
import sh1 from '../photos/shafi/1.jpg';
import sh2 from '../photos/shafi/2.jpg';
import sh3 from '../photos/shafi/3.jpg';
import sh4 from '../photos/shafi/4.jpg';
import sh5 from '../photos/shafi/5.jpg';
import sh6 from '../photos/shafi/6.jpg';
import sh7 from '../photos/shafi/7.jpg';
import sh8 from '../photos/shafi/8.jpg';
import sh9 from '../photos/shafi/9.jpg';
import sh10 from '../photos/shafi/10.jpg';
import sh11 from '../photos/shafi/11.jpg';
import sh12 from '../photos/shafi/12.jpg';
import sh13 from '../photos/shafi/13.jpg';
import sh14 from '../photos/shafi/14.jpg';
import sh15 from '../photos/shafi/15.jpg';
import sh16 from '../photos/shafi/16.jpg';
import sh17 from '../photos/shafi/17.jpg';
import sh18 from '../photos/shafi/18.jpg';
import sh19 from '../photos/shafi/19.jpg';
import sh20 from '../photos/shafi/20.jpg';
import sh21 from '../photos/shafi/21.jpg';
import sh22 from '../photos/shafi/22.jpg';
import sh23 from '../photos/shafi/23.jpg';
import sh24 from '../photos/shafi/24.jpg';
import sh25 from '../photos/shafi/25.jpg';
import sh26 from '../photos/shafi/26.jpg';
import sh27 from '../photos/shafi/27.jpg';
import sh28 from '../photos/shafi/28.jpg';
import sh29 from '../photos/shafi/29.jpg';
import sh30 from '../photos/shafi/30.jpg';
import sh31 from '../photos/shafi/31.jpg';
import sj1 from '../photos/sajjanar/1.jpg';
import sj2 from '../photos/sajjanar/2.jpg';
import sj3 from '../photos/sajjanar/3.jpg';
import sj4 from '../photos/sajjanar/4.jpg';
import sj5 from '../photos/sajjanar/5.jpg';
import sj6 from '../photos/sajjanar/6.jpg';
import sj7 from '../photos/sajjanar/7.jpg';
import sj8 from '../photos/sajjanar/8.jpg';
import sj9 from '../New_pics/vc_sajjanar_family/1.jpg';
import sj10 from '../New_pics/vc_sajjanar_family/4.jpg';
import sj11 from '../New_pics/vc_sajjanar_family/3.jpg';

import f1 from '../photos/first/1.jpg';
import f2 from '../photos/first/2.jpg';
import f3 from '../photos/first/3.jpg';
import f4 from '../photos/first/4.jpg';
import f5 from '../photos/first/5.jpg';
import f6 from '../photos/first/6.jpg';
import f7 from '../photos/first/7.jpg';
import f8 from '../photos/first/8.jpg';
import f9 from '../photos/first/9.jpg';
import f10 from '../photos/first/10.jpg';
import f11 from '../photos/first/11.jpg';
import f12 from '../photos/first/12.jpg';
import f13 from '../photos/first/13.jpg';
import f14 from '../photos/first/14.jpg';
import f15 from '../photos/first/15.jpg';
import f16 from '../photos/first/16.jpg';
import f17 from '../photos/first/17.jpg';
import f18 from '../photos/first/18.jpg';
import f19 from '../photos/first/19.jpg';
import f20 from '../photos/first/20.jpg';
import f21 from '../photos/first/21.jpg';
import f22 from '../photos/first/22.jpg';
import f23 from '../photos/first/23.jpg';
import f24 from '../photos/first/24.jpg';
import f25 from '../photos/first/25.jpg';
import f26 from '../photos/first/26.jpg';
import f27 from '../photos/first/27.jpg';
import f28 from '../photos/first/28.jpg';
import f29 from '../photos/first/29.jpg';
import f30 from '../photos/first/30.jpg';
import f31 from '../photos/first/31.jpg';
import f32 from '../photos/first/32.jpg';
import f33 from '../photos/first/33.jpg';
import f34 from '../photos/first/34.jpg';
import f35 from '../photos/first/35.jpg';
import f36 from '../photos/first/36.jpg';
import lx1 from '../photos/laxmareddy/1.jpg';
import lx2 from '../photos/laxmareddy/2.jpg';
import lx3 from '../photos/laxmareddy/3.jpg';
import lx4 from '../photos/laxmareddy/4.jpg';
import lx5 from '../photos/laxmareddy/5.jpg';
import lx6 from '../photos/laxmareddy/6.jpg';
import lx7 from '../photos/laxmareddy/7.jpg';
import lx8 from '../photos/laxmareddy/8.jpg';
import lx9 from '../photos/laxmareddy/9.jpg';
import lx10 from '../photos/laxmareddy/10.jpg';
import lx11 from '../photos/laxmareddy/11.jpg';
import lx12 from '../photos/laxmareddy/12.jpg';
import lx13 from '../photos/laxmareddy/13.jpg';
import lx14 from '../photos/laxmareddy/14.jpg';
import lx15 from '../photos/laxmareddy/15.jpg';
import lx16 from '../photos/laxmareddy/16.jpg';
import lx17 from '../photos/laxmareddy/17.jpg';
import lx18 from '../photos/laxmareddy/18.jpg';
import lx19 from '../photos/laxmareddy/19.jpg';
import lx20 from '../photos/laxmareddy/20.jpg';
import lx21 from '../photos/laxmareddy/21.jpg';
import lx22 from '../photos/laxmareddy/22.jpg';
import lx23 from '../photos/laxmareddy/23.jpg';
import mm1 from '../photos/office/1.jpg';
import mm3 from '../photos/office/3.jpg';
import mm2 from '../photos/office/2.jpg';
import mm4 from '../photos/office/4.jpg';
import mm5 from '../photos/office/5.jpg';
import p1 from '../photos/poonam/1.jpg';
import p2 from '../photos/poonam/2.jpg';
import p3 from '../photos/poonam/3.jpg';
import p4 from '../photos/poonam/4.jpg';
import p5 from '../photos/poonam/5.jpg';
import p6 from '../photos/poonam/6.jpg';

import sum1 from '../photos/sumati/1.jpg';
import sum2 from '../photos/sumati/2.jpg';
import sum3 from '../photos/sumati/3.jpg';
import sum4 from '../photos/sumati/4.jpg';
import sum5 from '../photos/sumati/5.jpg';
import sum6 from '../photos/sumati/6.jpg';
import sum7 from '../photos/sumati/7.jpg';
import sum8 from '../photos/sumati/8.jpg';
    
import g1 from '../New_pics/15th_anniversary/IMG-20240729-WA0018.jpg';
import g2 from '../New_pics/15th_anniversary/IMG-20240729-WA0019.jpg';
import g3 from '../New_pics/15th_anniversary/IMG-20240729-WA0020.jpg';
import g4 from '../New_pics/15th_anniversary/IMG-20240729-WA0021.jpg';
import g5 from '../New_pics/15th_anniversary/IMG-20240729-WA0022.jpg';
import g6 from '../New_pics/15th_anniversary/IMG-20240729-WA0023.jpg';
import g7 from '../New_pics/15th_anniversary/IMG-20240729-WA0024.jpg';
import g11 from '../New_pics/15th_anniversary/IMG-20240729-WA0025.jpg';
import g8 from '../New_pics/15th_anniversary/IMG-20240729-WA0026.jpg';
import g9 from '../New_pics/15th_anniversary/IMG-20240729-WA0027.jpg';
import g10 from '../New_pics/15th_anniversary/IMG-20240729-WA0028.jpg';

import va1 from '../New_pics/quiad_ratna_award/WhatsApp Image 2024-07-29 at 11.21.44_8c46df1f.jpg';
import va2 from '../New_pics/quiad_ratna_award/WhatsApp Image 2024-07-29 at 11.21.44_ba5d3076.jpg';

import m1 from '../New_pics/memu_saitam/1.jpg';
import m2 from '../New_pics/memu_saitam/2.jpg';

import ral from '../New_pics/railway_GM/1.jpg';
import ral2 from '../New_pics/railway_GM/2.jpg';
import ral3 from '../New_pics/railway_GM/3.jpg';
import ral4 from '../New_pics/railway_GM/5.jpg';
import ral5 from '../New_pics/railway_GM/4.jpg';
import ral6 from '../New_pics/railway_GM/6.jpg';

import it from '../New_pics/Ap_TS_IT_aprpiciation/1.jpg';
import it2 from '../New_pics/Ap_TS_IT_aprpiciation/2.jpg';
import it3 from '../New_pics/Ap_TS_IT_aprpiciation/3.jpg';
import it4 from '../New_pics/Ap_TS_IT_aprpiciation/4.jpg';
import it5 from '../New_pics/Ap_TS_IT_aprpiciation/5.jpg';
import it6 from '../New_pics/Ap_TS_IT_aprpiciation/6.jpg';

import mah from '../New_pics/mahankali_acp/1.jpg';
import mah2 from '../New_pics/mahankali_acp/2.jpg';
import mah3 from '../New_pics/mahankali_acp/3.jpg';

import ven from '../New_pics/boora_venkatesam/1.jpg';
import ven2 from '../New_pics/boora_venkatesam/2.jpg';
import ven3 from '../New_pics/boora_venkatesam/3.jpg';

import mur from '../New_pics/madhya_predesh_incharge/1.jpg';
import mur2 from '../New_pics/madhya_predesh_incharge/2.jpg';
import mur3 from '../New_pics/madhya_predesh_incharge/3.jpg';
import mur4 from '../New_pics/madhya_predesh_incharge/4.jpg';

import rama from '../New_pics/shri_ramana_chary/1.jpg';

import nage from '../New_pics/gajjela_nagesh/1.jpg';
import nage2 from '../New_pics/gajjela_nagesh/2.jpg';
import nage3 from '../New_pics/gajjela_nagesh/3.jpg';

import lax from '../New_pics/dr_laxmanji/1.jpg';
import lax2 from '../New_pics/dr_laxmanji/2.jpg';
import lax3 from '../New_pics/dr_laxmanji/3.jpg';

import ramc from '../New_pics/ramchanderji/1.jpg';
import ramc2 from '../New_pics/ramchanderji/2.jpg';
import ramc3 from '../New_pics/ramchanderji/3.jpg';

import chan from '../New_pics/abdul_kalam_award/1.jpg';
import chan2 from '../New_pics/abdul_kalam_award/2.jpg';
import chan3 from '../New_pics/abdul_kalam_award/3.jpg';
import chan4 from '../New_pics/abdul_kalam_award/4.jpg';
import chan5 from '../New_pics/abdul_kalam_award/5.jpg';
import chan6 from '../New_pics/abdul_kalam_award/6.jpg';

import jay from '../New_pics/jaychandra_ips/1.jpg';
import jay2 from '../New_pics/jaychandra_ips/2.jpg';
import jay3 from '../New_pics/jaychandra_ips/3.jpg';

import suma from '../New_pics/hero_suman/1.jpg';
import suma2 from '../New_pics/hero_suman/2.jpg';
import suma3 from '../New_pics/hero_suman/3.jpg';
import suma4 from '../New_pics/hero_suman/4.jpg';

import sharma from '../New_pics/sharmaji/1.jpg';
import sharma2 from '../New_pics/sharmaji/2.jpg';
import sharma3 from '../New_pics/sharmaji/3.jpg';
import sharma4 from '../New_pics/sharmaji/4.jpg';

import madhu from '../New_pics/madhakar_swami/1.jpg';
import madhu2 from '../New_pics/ravindar_acp/1.jpg';

import rams from '../New_pics/ramesh_acp/2.jpg';

import dev from '../New_pics/medical_infra_developers/1.jpg';
import dev2 from '../New_pics/medical_infra_developers/2.jpg';
import dev3 from '../New_pics/medical_infra_developers/3.jpg';
import dev4 from '../New_pics/medical_infra_developers/4.jpg';

import ms from '../New_pics/madukar_swami_ceo/1.jpg';
import ms2 from '../New_pics/madukar_swami_ceo/2.jpg';
import ms3 from '../New_pics/madukar_swami_ceo/3.jpg';


import gane from '../New_pics/ganesh_mla/1.jpg';
import gane2 from '../New_pics/ganesh_mla/2.jpg';
import gane3 from '../New_pics/ganesh_mla/3.jpg';
import gane4 from '../New_pics/ganesh_mla/4.jpg';

import nagea from '../New_pics/nage_rao/1.jpg';

import mr from '../New_pics/m_rajashekar/1.jpg';
import mr2 from '../New_pics/m_rajashekar/2.jpg';
import mr3 from '../New_pics/m_rajashekar/3.jpg';
import mr4 from '../New_pics/m_rajashekar/4.jpg';

import sr from '../New_pics/sridharji/1.jpg';
import sr2 from '../New_pics/sridharji/2.jpg';
import sr3 from '../New_pics/sridharji/3.jpg';

import vasu from '../New_pics/vasuji/1.jpg';
import vasu2 from '../New_pics/vasuji/2.jpg';
import vasu3 from '../New_pics/vasuji/3.jpg';

import chand from '../New_pics/chandrashekar/1.jpg';
import chand2 from '../New_pics/chandrashekar/2.jpg';
import chand3 from '../New_pics/chandrashekar/3.jpg';
import chand4 from '../New_pics/chandrashekar/4.jpg';



Modal.setAppElement('#root'); // For accessibility

const events = [
  {
    event: "15th Anniversary Celebrations with Chief Guest  Hon'ble Smt.Tamilisai Soundararajan Governor Telangana & Puducherry, and Prominent Guests",
    images: [
      g1,
      g2,
      g3,
      g4,
      g5,
      g6,
      g7,
      g11,
      g8,
      g9,
      g10,
      
      
    ],
  },
  {
    event: '2019-Vaidya Ratna Award By Telangana Govt Health Care Awards',
    images: [
      va1,
      va2,
      
      
    ],
  },
  {
    event: '2015- Memu Saitham Program Got an appriciation And Gift By Memu Saitham Team',
    images: [
      m1,
      m2,
      
      
    ],
  },
  {
    event: "Hon'ble Arun Kumar Jain (IRSSE)  South Central Railway (GM) , Shri Munna Kumar RVNL (CPM) , and Sridhar (ACP) Tirumalagiri",
    images: [
     ral,
     ral2,
     ral3,
     ral4,
     ral5,
     ral6,

      
    ],
  },
  {
    event: 'Shri.B.Ramakotaiah (IRS), ( Income Tax Commissioner with family ) Visited And Supported',
    images: [
      r1,
      r2,
      r3,
      r4
    ],
  },
  {
    event: 'Great Appriciation From AP&TG All Income Tax Commissioners and Shri.Atul Pranay Principal Chief Commissioner and all IT Officiers ',
    images: [
     it,
    
     it3,
     it4,
     it5,
     it6,
     
      
    ],
  },
  {
    event: 'Mahankali ACP and Team Visited And Supported',
    images: [
    mah,
    mah2,
    mah3,


     
      
    ],
  },
  {
    event: "Hon'ble Burra Venkatesham (IAS) Pricipal Secratary to Education of TG",
    images: [
    ven,
    ven2,
    ven3,


     
      
    ],
  },
  {
    event: 'Shri.Muralidar Rao ji Madhya Pradesh Incharge(BJP Party) Visited and Supported',
    images: [
    mur,
    mur2,
    mur3,
    mur4,

     
      
    ],
  },
  {
    event: 'Shri.Dr.K.V.Ramana Chary (IAS) Felicitated for good service',
    images: [
    rama,
    chan6,

     
      
    ],
  },
  {
    event: 'Shri.Gajjela Nagesh Garu (Food And Beverages Chair Person Telangana)',
    images: [
    nage,
    nage2,
    nage3,

     
      
    ],
  },
  {
    event: 'Dr.K.Laxman ji (MP Rajya Sabha) (OBC National President BJP) He Visited And Celebrated Modi ji Birthday Celebrations',
    images: [
    lax,
    lax2,
    lax3,

     
      
    ],
  },
  {
    event: 'Shri.Ramchander Rao (MLC) Visited And Supported',
    images: [
    ramc,
    ramc2,
    ramc3,

     
      
    ],
  },
  {
    event: "Award from Abdul Kalamji Life time Achievement From Hon'ble Justice Chandraiah (State Human Rights Chairperson) also He Visited And Supported",
    images: [
    chan,
    chan2,
    chan3,
    chan4,
    chan5,
    

     
      
    ],
  },
  {
    event: 'Shri.S.K.JayaChandra (IPS) Former (DGP) AP & TG united Visted and Supported',
    images: [
    jay,
    jay2,
    jay3,

     
      
    ],
  },
  {
    event: 'Shri.Suman (Indian Actor) Visted and Supported',
    images: [
    suma,
    suma2,
    suma3,
    suma4,

     
      
    ],
  },
  {
    event: 'BR Shafi , Daivagna Sharma (Numerologist), Dr.P.Sudhakar Naik IRS (Joint commissioner of IT at Mumbai)',
    images: [
    sharma,
    sharma2,
    sharma3,
    sharma4,

     
      
    ],
  },
  {
    event: 'Shri.Madhukar Swamy (DCP) , Crimes Rachakonda & Shri.Ravindar (ACP) Mahankali Visited And Supported',
    images: [
   madhu,
   madhu2
     
      
    ],
  },
  {
    event: 'Shri.Ramesh (ACP) Tirumalagiri Visited And Supported',
    images: [
  rams,
     
      
    ],
  },

  {
    event: 'Telangana Medical Infra Developers , Shri.Srinivas Rao & Dr.J.Venkati DMHO and Team Hyderabad Visited And Supported',
    images: [
  dev,
  dev2,
  dev3,
  dev4,
     
      
    ],
  },
  {
    event: 'Shri.Madhukar Naik (CEO) and Officers SCB Visited And Supported',
    images: [
 ms,
 ms2,
 ms3,
 
     
      
    ],
  },

  {
    event: 'Shri.Sri Ganesh ( MLA CANTONMENT ) and Dr.Ashish Chowhan Apollo Hospital Visited And Supported',
    images: [
gane,
gane2,
gane3,
gane4,

     
      
    ],
  },

  {
    event: 'Shri.Nageshwar Rao (ACP) Mahankali with Family Visited And Supported',
    images: [
nagea,

     
      
    ],
  },

  {
    event: 'Shri.Marri Rajashekar Reddy Former MP Visited And Supported',
    images: [
mr,
mr2,
mr3,
mr4,

     
      
    ],
  },

  {
    event: 'Shri.Lingam Sridhar ji Telangana RSS Head Visited And Supported',
    images: [
sr,
sr2,
sr3,

     
      
    ],
  },
  {
    event: 'Shri.Vasu ji Seva Bharath Head Telangana Visited And Supported',
    images: [
vasu,
vasu2,
vasu3,

    ],
  },
  {
    event: 'Shri.Chandrashekar Tiwari Organizing Secretary Telangana Incharge BJP Visited And Supported',
    images: [
chand,
chand2,
chand3,
chand4,

    ],
  },

  {
    event: 'Smt.Swathi Lakra (IPS) - ( Addl DGP, Women Safety including She Teams and Bharosa centres, Telangana State Visited And Supported',
    images: [
      s1,
      s2,
      s3,
      s4,
      s5,
      s6,
      s7,
      s8,
    ],
  },
  {
    event: 'Shri.Krishna Sagar Rao - ( Telangana BJP SpokesPerson ) Visited And Supported',
    images: [
      k1,
      k2,
      k3,
      k4,
      k5,
      k5,
      k6,
      k7,
    ],
  },
  {
    event: 'Dr.P.Sudhakar Naik (IRS), ( Joint Commissioner of Income Tax ) Visited And Supported',
    images: [
      su1,
      su2,
      su3,
      su4,
      su5,
      su6,
      su7,
    ],
  },
 
  {
    event: 'Shri.T.P.Das DGP United AP&TG Visited And Supported',
    images: [
     t1,
     t2,
     t3,
     t4,
    ],
  },
  {
    event: 'Shri.B.Ajith Reddy CEO, SECUNDERABAD CANTONMENT BOARD',
    images: [
      aj1,
      aj2,
      aj3,
      aj4,
      aj5,
      aj6,
      aj7,
      aj8,
      
    ],
  },
  {
    event: 'Shri.Br.Shafi Motivational Speaker Visited And Supported',
    images: [
      sh1,

sh4,
sh5,

sh7,

sh9,
sh10,


sh13,
sh19,
sh26,
sh29,

    ],
  },
 
  {
    event: "Shri. V.C.SAJJANAR, IPS TG (CP Cyberabad) On 15-Aug-2020 Also He Visited Multiple Times And Supported",
    images: [
      sj1,
sj2,
sj3,
sj4,
sj5,
sj6,
sj7,
sj8,
sj9,

sj11,

    ],
  },
  {
    event: "VIP's With Staff Gallery",
    images: [
      f1,
f2,
f3,
f4,
f5,
f6,
f7,
f8,
f9,
f10,
f11,
f12,
f13,
f14,
f15,
f16,
f17,
f18,
f19,
f20,
f21,
f22,
f23,
f24,
f25,
f26,
f27,
f28,
f29,
f30,
f31,
f32,
f33,
f34,
f35,
f36,

    ],
  },
  {
    event: "Shri.Lakshma Reddy (TG Health Minister) inaugurated Mahendra Hills Branch On 07th August 2016 And Appreciated Our Services.",
    images: [
      lx1,
lx2,
lx3,
lx4,
lx5,
lx6,
lx7,
lx8,
lx9,
lx10,
lx11,
lx12,
lx13,
lx14,
lx15,
lx16,
lx17,
lx18,
lx19,
lx20,
lx21,
lx22,
lx23,

    ],
  },
  {
    event: "Smt.Minnie Mathew (IAS) Former Chief Secretary of AP - Appreciated Our Services Visited And Supported",
    images: [
      mm1,
      mm2,
      mm3,
      mm4,
      mm5,
    ],
  },
  {
    event: "Smt.Poonam Malakondaiah (IAS) Principal Secretary Of AP - Appreciated Our Services Visited And Supported 07th November 2015 ",
    images: [
      p1,
      p2,
      p3,
      p4,
      p5,
      p6,
    ],
  },
  {
    event: "Smt.B.SUMATHI (IPS) Appreciated Our Services Visited And Supported",
    images: [
      sum1,
      sum2,
      sum3,
      sum4,
      sum5,
      sum6,
      sum7,
      sum8,

    ],
  },
  // Add more events and images as needed
];

const Gallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);

  const openModal = (images, index) => {
    setCurrentImages(images);
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
  };

  const showPrevImage = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + currentImages.length) % currentImages.length
    );
  };

  return (
    <>
    <div className="gallery">
      <h1>Photos</h1>
      {events.map((event, eventIndex) => (
      
        <div key={eventIndex} className="event-section">
          
          <h2>{event.event}</h2>
          <div className="event-images">
            {event.images.map((src, imageIndex) => (
              <img
                key={imageIndex}
                src={src}
                alt={`Gallery ${imageIndex}`}
                onClick={() => openModal(event.images, imageIndex)}
                className="gallery-image"
              />
            ))}
          </div>
        </div>
        
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <button className="close-button" onClick={closeModal}>Close</button>
        <button className="prev-button" onClick={showPrevImage}>Previous</button>
        <img src={currentImages[currentIndex]} alt={`Image ${currentIndex}`} className="modal-image" />
        <button className="next-button" onClick={showNextImage}>Next</button>
      </Modal>
      
    </div>
    <div className="news-section">
    <h2>Latest News</h2>
    <NewsScroller />
  </div>
  </>
  );
};

export default Gallery;
