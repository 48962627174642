import React from 'react';
// Import images for VIPs if available (e.g., images of the VIPs)
import vipImage1 from '../images/temp/hanuman.jpg'; // Replace with actual paths
import vipImage2 from '../images/temp/sajjanar.jpg'; 
import vipImage3 from '../images/temp/poonam.jpg'; 
import vipImage4 from '../images/temp/minnie.jpg'; 
// import vipImage5 from './path-to-image5.jpg'; 
// import vipImage6 from './path-to-image6.jpg'; 
// import vipImage7 from './path-to-image7.jpg'; 
// import vipImage8 from './path-to-image8.jpg'; 
// import vipImage9 from './path-to-image9.jpg'; 
// import vipImage10 from './path-to-image10.jpg'; 
// import vipImage11 from './path-to-image11.jpg'; 
// import vipImage12 from './path-to-image12.jpg'; 
// import vipImage13 from './path-to-image13.jpg'; 
// import vipImage14 from './path-to-image14.jpg'; 
// import vipImage15 from './path-to-image15.jpg'; 
// import vipImage16 from './path-to-image16.jpg'; 
// import vipImage17 from './path-to-image17.jpg'; 
// import vipImage18 from './path-to-image18.jpg'; 
// import vipImage19 from './path-to-image19.jpg'; 
// import vipImage20 from './path-to-image20.jpg'; 
import './vip_opinion.css';
import NewsScroller from '../Home/Carousel';

const VIPsOpinions = () => {
    const opinions = [
        {
            name: "PADMASHREE Dr. Hanuman Chowdary",
            position: "Director - Centre for Telecom Management & Studies Chairman - Pragna Bharati Telangana & Andhra Pradesh",
            opinion: "Humanitarian Work is God’s Work. Maanawaseva is Madhavaseva. Overly impressed with the great compassionate work the institution is doing. I thank all those involved.",
            image: vipImage1
        },
        {
            name: "Sri. V.C Sajjanar - I P S",
            position: "Commissioner of Police, Cyberabad",
            opinion: "Dr. Rama Krishna is doing a great service. I really appreciate and touched with their selfless service. All the best RK his team and they are going a great humanitarian service.",
            image: vipImage2
        },
        {
            name: "Smt. Poonam Malakondaiah - I A S",
            position: "Principal Secretary to Health, A.P",
            opinion: "Congratulations! to Dr. Rama Krishna garu and his team for his impeccable services rendered. Service to Humanity is Service to God. May God give him strength, vitality, and vigor to serve mankind. It was wonderful spending time with him and visiting his facility. All the Best.",
            image: vipImage3
        },
        {
            name: "Smt. Minnie Mathew Garu - I A S",
            position: "AP Chief Secretary, Member of AP & Telangana Administrative Tribunal",
            opinion: "Dr. Rama Krishna is doing great and wonderful services to many old aged helpless people. I really appreciate and all the best for his future endeavors.",
            image: vipImage4
        },
        {
            name: "Mathew C Kunnumkal, IAS,(Retd.) Former Secretary, GOI.",
            opinion: "Dr. Ramakrishna’s Health Care Center has been doing a commendable service in providing dedicated quality Health Care to the old and deserving patients who do not get any support or help from their near and dear ones. Infact, the centre run by Dr. B. Ramakrishna, has been a place of hope and love for hundreds of dislocate and discarded persons whom the society has rejected for one reason or other. It is only good souls like Dr. Ramakrishna, can sustain with no tangible support from outside. I hope the center will grow and bloom into a major Health Care Center, providing hope and health care to the downtrodden and even others with financial capacity, a place where one can get dedicated and quality Health Care.",
            // image: vipImage5
        },
        {
            name: "Sri. B. Venkatesham - I A S",
            position: "Principal Secretary to BC Welfare, Telangana",
            opinion: "Great Service, Seeing is Believing, Words cannot express the service being done. You can only feel and fail to express the humanitarian work going on here, I salute RK for such wonderful work.",
            // image: vipImage6
        },
        {
            name: "Smt. B. Sumathi - I P S",
            position: "Deputy Inspector General of Police, Womens Wing",
            opinion: "RK is doing a marvelous service to the Humanity. I wish him and his team a great success in serving the needy and poor.",
            // image: vipImage7
        },
        {
            name: "Dr. C. Laxma Reddy - Former Health Minister, Govt of Telangana",
            opinion: "I am happy to see you. It is a wonderful environment; such young guy has established, and he is doing great service. This is really heart touching service. Keep doing this. I wish you all the best Dr. Ramakrishna!",
            // image: vipImage8
        },
        {
            name: "Justice G. Chandraiah",
            opinion: "Excellent care and support for the persons, who need the most. All appreciation for the commitment of Dr. Ramakrishna. I wish I had known him earlier. He deserves encouragement by all means. Dr. Ramakrishna invited me to visit this premises. I am very glad to visit this premises. I participated in 13th year Anniversary of RK’s Mother Teresa Foundation & Elder Care Center. I spoke to the inmates. They are very happy because of the care taken by Dr. Ramakrishna and Mrs. Ramakrishna. I appreciate their service and the inmates. I wish them all the best.",
            // image: vipImage9
        },
        {
            name: "B. Ajith Reddy, CEO, SCB",
            opinion: "The care for the Elderly and the most needed people in the society is a moral responsibility on each and every individual of ours. We, as a whole, can sustain in the long run if all the people, including the old and disabled, has a right to live with dignity. I commend Dr. Ramakrishna and his team for their inspiration work. All the very best.",
            // image: vipImage10
        },
        {
            name: "Dr. Anuradha Medoju, Sr. RD & Chief AIHO, MOH & FW, GOI",
            opinion: "Visited Mother Teresa Elderly disabled Center, interacted bedridden disability patients. They are so happy living here feeling like their home. Dr. Ramakrishna is doing a wonderful & great work. And I appreciate you and all the staff to do this extraordinary work. Wish you best in all entirely for the future endeavours. God bless you.",
            // image: vipImage11
        },
        {
            name: "Shaik Shafiullah ( Brother Shafi)",
            opinion: "This service by Ramakrishna Garu is wonderful. His humanity is extraordinary. May Almighty God bless RK Garu and all the people stay here. I am so much impressed with the RK’s Foundation. Please Be Continue.",
            // image: vipImage12
        },
        {
            name: "Swati Lakra, ADDL DGP, (Women safety), Telangana",
            opinion: "I felt blessed to visit this beautiful Home. The elderly looked here happy and contented which shows the dedication with which Sri Ramakrishna Garu and his team members work. I wish the entire team my good wishes for their future endeavours and may God bless all of them.",
            // image: vipImage13
        },
        {
            name: "R. Vijay Kumar Balan Nair, CEO, Contonment Board, Secunderabad",
            opinion: "Dr. Ramakrishna’s Health Care Center for the Aged, under the aegis of Mother Teresa Foundation Elder Care Center is a very noble and dedicated Service to the community especially, the sick and aged who are homeless. I was touched with the dedicated Service rendered by Dr. Ramakrishna towards the sick and aged with utmost care and medication. The response from the patients and sick during my visit towards Dr. Ramakrishna was as a family member and their strive to live their life with hope. On this day, I pay respect and gratitude towards all the sick and aged patients and wish Dr. Ramakrishna to fulfill all his dreams in helping the sick and aged, and all help and success in his endeavours towards care for the Elderly.",
            // image: vipImage14
        },
        // {
        //     name: "Dr. T. H. Choudhary",
        //     opinion: "Humanitarian work God’s work. Manava seve Madhava seva. Highly impressed with the great compassionate work the institution is doing. I thank all those involved.",
        //     // image: vipImage15
        // },
        {
            name: "Raja Gopal Lagadapati, Ex. MP, Vijayawada",
            opinion: "I am overwhelmed to see the love care and affection the staff of Mother Teresa medical services is showing on the inmates. I also wish the management and staff all the best in their efforts to provide the best care to the needy. Duty without love is deplorable. Duty with love is desirable but love without duty is divine. With Best Wishes!",
            // image: vipImage16
        },
        {
            name: "Prof. Dr. M. K. Bhandari & Dr. Shobha Bhandari",
            opinion: "Me and my wife Dr. Shobha have been regularly visiting the Mother Teresa Foundation Elder Care Home, very successfully run by Dr. Ramakrishna and his wife and his teammates. Dr. Ramakrishna is a dedicated health care specialist, providing excellent health care, treatment, and food to nearly 50 Elderly persons. In this modern age, when people hardly manage to Elderly people. This center is taking care of those people who have been either abandoned by their own kins or who don’t have anyone to take care of them. Excellent and incredible. I wish more and more people and resourceful persons should come forward to strengthen and morally and financially support Dr. Ramakrishna and his missionary enterprise. May God bless all inmates with peace and good health.",
            // image: vipImage17
        },
        {
            name: "A. Dakshayani, Managing Trustee Ramky Foundation",
            opinion: "You are doing excellent work. All the best & you need any help, please call.",
            // image: vipImage18
        },
        {
            name: "K. Krishna Sagar Rao",
            opinion: "Dr. Ramakrishna and his family are doing which own children of their aged individuals are not rendering. The inmates in this Care Home are the blessed ones. They are being well fed and cared by Dr. RK and his team. The facility needs all the support it can get. Dr. RK needs to be supported to do more service to many such individuals. I’m touched by what I have seen here will certainly enter by humble support to the great initiative led by Dr. RK. Best Wishes of Gratitude.",
            // image: vipImage19
        },
        {
            name: "Ajay Mishra",
            opinion: "Excellent care and support for the persons, who need the most. All appreciation for the commitment of Dr. Ramakrishna. I wish I had known him earlier. He deserves encouragement by all means.",
            // image: vipImage20
        }
    ];

    return (
        <div className="vips-opinions">
            <h2>VIP’S OPINIONS</h2>
            {opinions.map((vip, index) => (
                <div key={index} className="opinion-card">
                    <img src={vip.image} alt={""} className="vip-image" />
                    <div className="opinion-text">
                        <h3>{vip.name}</h3>
                        <p className="position">{vip.position}</p>
                        <p>{vip.opinion}</p>
                    </div>
                </div>
            ))}
            <div className="news-section">
        <h2>Latest News</h2>
        <NewsScroller />
      </div>
        </div>
    );
};

export default VIPsOpinions;
